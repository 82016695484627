body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toast {
  position: absolute;
  z-index: 200000 !important;
}

/*admin fix kingy */
.adminHolder {
  padding-top: 60px;
}

#checkouttweak {
  z-index: 999999;
}

/*notificaiton area*/
.notifications-holder {
  z-index: 100;
  width: 400px;
  text-align: left;
}

// .dropdown {
//   position: static !important;
// }

// .dropdown-menu {
//   box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
//   margin-top: 0px !important;
//   width: 100% !important;
// }

.loading_overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .5);
  z-index: 2000;
}

/*qr*/

.hide {
  display: none;
  color: black !important;
}


/* Data Table */
.dataTableExpandable:hover {
  background: -webkit-linear-gradient(top, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
  /* Standard syntax (must be last) */
}

/* Data Table */
.tableHover {
  border-width: 3px !important;
}

/* Data Table */
.tableHover:hover {
  background: blue !important;
}

/* Data Table */
table.center {
  margin-left: auto;
  margin-right: auto;
}

/* Data Table */
.bg-light-success {
  background-color: #70f18e !important;
}

/* Data Table */
.bg-light-danger {
  background-color: #fa98a2 !important
}

/* Data Table */
.bg-light-warning {
  background-color: #ffe69d !important
}


/* Data Table Sticky Table */
.table {
  thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.bold {
  font-weight: bold !important;
}

/* Data Table Sticky Table */

.tableEven {
  background: #FFF
}

.tableOdd {
  background: #CCC
}



.pull-left {
  text-align: left;
}

.pull-right {
  text-align: right;
}


.grid-divider {
  overflow-x: hidden; //quickfix to hide divider on left side
  position: relative;
}

.grid-divider>[class*="col-"]:nth-child(n + 2):after {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  bottom: 0;

  @media (max-width: 767px) {
    width: 100%;
    height: 1px;
    left: 0;
    top: -4px; // quickfix to center line between columns
  }

  @media (min-width: 768px) {
    width: 1px;
    height: auto;
    left: -1px; // quickfix to hide divider on left side
  }
}

.table td,
.table th {
  vertical-align: middle !important;
}

.black {
  background-color: black;
}

.gold {
  background-color: #a88647;

  @media (max-width: 992px) {
    background-color: black;
  }
}

.white-font {
  color: white;
}

.middle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tableEven {
  background: #FFF
}

.tableOdd {
  background: #CCC
}


.hiddenTable {
  overflow: hidden;
  height: 0;
}

.printH1 {
  font-size: 100px;
  padding-bottom: 50px;
}

.printH2 {
  font-size: 100px;
  padding-top: 50px;
}

.btn-primary {
  color: #fff;
  background-color: black !important;
  border-color: black !important;
}

.btn {
  border-radius: 0 !important;
}

.wow {
  color: white !important;
}

.white {
  color: white !important;
}

.divide {
  border-color: white;
}

/* kingy is gay */
.whydiineedtodoitlikethisasian {
  font-size: large !important;
  font-weight: bold !important;

}

.nav-link {
  color: black !important;

  @media (max-width: 992px) {
    color: #a58f62 !important;
  }
}

.nav-link:hover {
  color: white !important;
}

//*FORM CONTROL*//

.login-div {
  width: 500px;
  height: 100%px;
  padding: 100px 20px;

  //*background: radial-gradient(circle, #555555, #535353, #505050, #4e4e4e, #4c4c4c);*//
  margin: auto;
  background-color: white;

  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    padding: none !important;
    border-style: none;
    border-color: none;
    border-radius: none;

  }
}

.title {
  text-align: center;
  font-size: 28px;
  padding-top: 15px;
  letter-spacing: 0.5px;
  color: black;
}

.sub-title {
  text-align: center;
  font-size: 15px;
  padding-top: 7px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
}


.fields {
  width: 100%;
  padding: 100px 5px 30px 5px;
}


.fields input {
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  font-family: 'arial', sans-serif;
  color: black;
  padding: 10px 10px 10px 5px;
}

.loginfix2 {
  display: block;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  color: gray;
  font-size: 15px;
  font-weight: 300;
}

.signin-button {
  outline: black;
  border: 3px solid #a88647;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 0px;
  font-size: 22px;
  font-family: 'Lato', sans-serif;
  color: #a88647;
  text-align: center;
  letter-spacing: 1px;
  background: radial-gradient(circle, #03110e, #061d18, #041411, #051612, #030c0a);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.signin-button:hover {
  background: radial-gradient(circle, #a88647, #d69d32, #c99932, #c78d36, #573e10);
  color: black;
}

.signin-button:active {
  background: radial-gradient(circle, #1da88a, #1ea084, #229c82, #23947b, #238d76);
}

.link {
  padding-top: 20px;
  text-align: center;
}

.link a {
  text-decoration: none;
  color: gray;
  font-size: 20px;
}

.loginbody {
  padding: 100px;

  @media (max-width: 992px) {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}

.innermainmenu {
  margin: 0 auto;
  /*margin-top: -40px !important; when loaded to server*/
  background-color: black;
  border-width: 6px;
  border-style: solid;
  border-color: #b89f6e;
  z-index: 9;
  position: relative;

  @media (max-width: 992px) {
    visibility: hidden;
    display: none;
    height: 0px;
    width: 0px;

  }


}

.innermainmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
  padding: 20px 20px 20px;
}

.innermainmenu ul li {
  float: none;
  display: inline-block;
}

.innermainmenu ul li:nth-child(1) {
  float: left;
}

.innermainmenu ul li:nth-child(2) {
  float: left;
}

.innermainmenu ul li a {
  color: white !important;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  padding: 10px 30px;
  font-weight: bold;


}

.innermainmenu ul li a:hover {
  color: #a58f62 !important;
}

.logomenu {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 55px;
  margin-left: -190px;
  z-index: 11;

  @media (max-width: 992px) {
    visibility: hidden;
  }
}

.mainmenu {

  z-index: 10;

  @media (max-width: 992px) {
    display: none;
    visibility: hidden;
    width: 0px;
    height: 0px;

  }
}

#basic-navbar-nav {
  padding-bottom: 2.2em !important;
}

.navbar-nav {

  right: -200px
}

.navbarmenu {

  margin: 0 auto;
  padding-top: 50px;
  z-index: 10;
  position: relative;

  @media (max-width: 992px) {}
}


.navback {
  width: 100%;
  padding: 93px;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  top: 0px;

  @media (max-width: 992px) {
    visibility: hidden;
  }
}

.mainpage {
  top: -50px;
  position: relative;
  z-index: 0;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
  top: 0px !important;
}

.hidden {
  @media (min-width: 992px) {
    visibility: hidden;
  }
}

.standarddiv {
  width: 70%;
  height: 100%;
  padding: 100px;

  //*background: radial-gradient(circle, #555555, #535353, #505050, #4e4e4e, #4c4c4c);*//
  margin: auto;
  background-color: white;

  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    border-style: none;
    border-color: none;
    border-radius: none;
    padding: 0px 10px;
  }
}

.card-title {
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
}

.card-text {
  text-align: left;
}

.btn .btn-primary {
  text-transform: uppercase !important;
}

.right {
  text-align: right;
}

// .dropdown-menu {
//   max-width: 400px !important;
//   left: 69% !important;

//   @media (max-width: 992px) {
//     max-width: 100%;
//     left: 0;
//   }
// }


/* Footer */
.footer {
  background-color: black;
}

.footer a {
  padding: 12px;
  color: #a88647 !important;
  text-decoration: none;
  font-size: 17px;
  width: 33.3%;
  text-transform: uppercase;
}

.footer hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0;
  border-top: 4px solid #a88647 !important;
}

.footerlogo {
  width: 15px;
  height: 15px;
  position: relative;
  left: 50%;
  top: -100px;
  margin-left: -137px;
  z-index: 10;

  @media (max-width: 992px) {
    visibility: hidden;
  }
}

.resize {
  width: 250px;
  height: auto;
  padding-top: 20px !important;
}

.resizenav {
  width: 100px;
  height: auto;
  margin: auto;
}

.rowfix {
  padding-bottom: 70px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

/*
    DEMO STYLE
*/
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

i,
span {
  display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: black;
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header {
  padding: 20px;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386D5;
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 10px;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386D5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    Admin style
----------------------------------------------------- */

#content {
  width: 100%;
  // padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

.admini {
  position: relative;
}

.adminlogo {
  width: 155px;
  height: 85px;
}

.rowadmin {

  padding-left: 20%;
  padding-right: 20%;

  @media (max-width: 992px) {
    padding-left: 1px;
    padding-right: 1px;

  }
}

/* ---------------------------------------------------
    Main Carousel
----------------------------------------------------- */


//*carousel*//

.carouselheader {
  font-size: 2em;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  word-wrap: break-word;
}

.carouselword {
  font-size: 1em;
  text-align: left;
  text-transform: uppercase;

}

.op {
  filter: brightness(50%)
}

.front {
  position: absolute;
  z-index: 100
}


.carousel-caption {
  position: absolute;
  top: 60%;
}

.carouselbutton {
  position: absolute;
  right: 0;
  border: 3px solid white;
}

.but {
  font-size: 20px;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 0px;
  font-size: 22px;
  font-family: 'Lato', sans-serif;
  color: white;
  text-align: center;
  letter-spacing: 1px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background: #a88647;
}

.but:hover {
  background: radial-gradient(circle, #2fdbb6, #32d6b3, #32c9a8, #36c7a7, #38bb9f);
}

.but:active {
  background: radial-gradient(circle, #1da88a, #1ea084, #229c82, #23947b, #238d76);
}



.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1200px !important;
}

.p-md-5 {
  padding: 3rem !important;
  background-color: lightgrey;
}

.icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: black;
  margin: 0 auto;
  margin-bottom: 20px;
  color: #a88647;
}


.form-control {
  border-width: 0px !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-bottom: 1px #040404 solid !important;
  padding-bottom: 2px;
  margin-bottom: 20px !important;
}

.form-control:focus {
  border-bottom: 1px #24b337 solid !important;
  padding-bottom: 2px;
}

::-webkit-input-placeholder {
  /* Edge */
  font-size: 1.3rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #b68013 !important;

}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b68013 !important;
  font-size: 1.3rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #b68013 !important;
}

::placeholder {
  color: #b68013 !important;
  font-size: 1.3rem !important;
  font-weight: 400 !important;

}

option:first {
  font-weight: 400;
  line-height: 1.5;
  color: #b68013 !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
}

.form-error {
  background: url(c.svg) !important;
  background-size: 20px !important;
  background-repeat: no-repeat !important;
  background-position: 100% 10px !important;
}



/* login  */

.fixlogin {
  width: 90% !important;
}

.buttonfix {
  width: 50% !important;
}

.contactfix {
  padding-left: -20px !important;
  padding-right: -20px !important;
}

.cardfix {
  border: none !important;
}

.flufix {
  top: 0px;
}

.bottomfix {
  padding-bottom: 60px !important;
}

hr.gold {
  border-top: 3px solid #a88647;
}

.form-control-tweaktarea {
  border-width: 1px !important;
  box-shadow: none !important;
  border: 1px #040404 solid !important;
  margin-bottom: 2px !important;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 3.5;
  color: #b68013;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.linkfix {
  text-transform: none !important;
}

.navcontainerfix {
  max-width: 1200px;

  @media (max-width: 992px) {
    max-width: 100% !important;
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
}

.reqbtnfix {
  @media (max-width: 992px) {
    font-size: 15px !important;
  }
}

.splash-div {
  width: 600px;
  height: 100%px;
  padding: 100px 20px;
  margin: auto;

  @media (max-width: 992px) {
    width: 100% !important
  }
}

.form-control {
  font-size: 1.3rem !important;
  color: #b68013 !important;
  font-weight: 400 !important;
}

.h2,
h2 {
  font-size: 3rem !important;
}

body {
  font-size: 1.3rem !important;

}

a {
  color: #a88647 !important;
}

.goldfont {
  color: #a88647 !important;
}

.cardrequest {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid #a88647 !important;
  border-radius: 1rem !important;
}

hr.line {
  padding: 0;
  border: 3px solid #a88647;
  text-align: center;
}

.mydiv {
  background: white;
  position: absolute;
  top: 35px;
  left: 5%;
}

.hidemobile {
  @media (max-width: 992px) {
    display: none !important;
  }

}

.hidedesktop {
  @media (min-width: 992px) {
    display: none !important;
  }

}

/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #a88647;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #a88647;
  font-size: 20px;
  line-height: -1;
  left: 16px;
}

.input-container input {
  height: 64px;
  border-radius: 1px;
  border: none;
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}

.paddingtop {
  padding-top: 25px;

  @media (max-width: 992px) {
    padding-top: 1% !important
  }
}

.paddingtop1 {
  padding-top: 22px;

  @media (max-width: 992px) {
    padding-top: 1% !important
  }
}

.btn-gold {
  color: #a88647 !important;
  background: black !important;
}

.paddingquantity {
  padding-left: 10px;
  padding-right: 10px;
}

.fc-tweak {
  border-width: 1px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px #b68013 solid !important;
  padding-bottom: 2px;
  margin-bottom: 20px !important;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 3.5;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .25rem !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.commentadd {
  left: 32px !important;
}

.flex0 {
  flex: 0 0 0 !important;
}

/*h2 {
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black; 
  color: #dfb86c !important;
}
*/
h2 {
  color: black;
  font-family: sans-serif !important;
  -webkit-text-fill-color: #dfb86c;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

/*h3 {
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black; 
  color: #dfb86c !important;
}*/

h3 {
  color: black;
  font-family: sans-serif !important;
  -webkit-text-fill-color: #dfb86c;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.nomargin {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.profile-class {
  color: #b68013 !important;

  @media (max-width: 992px) {
    color: #b68013 !important;
  }

}

.profile-class:hover {
  color: black !important;
}


.notLiveBar {
  background-color: red !important;
  color: black !important;
}


// .bold-cursor{
//   cu
// }


.react-select-hi-contrast input:focus {
  outline: none !important;
  caret-color: blue;
  font-weight: bold;
}

.hiContrastFormField:focus {
  outline: none !important;
  border: 2px solid blue !important;
  caret-color: blue;
}


.hiContrastFormField {
  border-width: 0px !important;
  border: 2px solid lightgray !important;
  padding-bottom: 2px;
  margin-bottom: 20px !important;
  width: 100%;
}


.focus:focus {
  background-color: blue !important;
}

.descriptionText {
  color: grey;
}


// .ffsAwal {
//   padding-bottom: 6em !important;
//   //  padding-top: 2em !important;
// }